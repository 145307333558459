<template>
<b-card v-if="users" no-body class="card-company-table">
    <table class="table b-table" v-if="users">

        <thead>
            <tr>
                <th scope="col" v-for="field in fields" :key="field.key">{{field.label}}</th>
            </tr>
        </thead>

        <draggable v-model="users" tag="tbody" handle=".handle">
            <tr v-for="(item,index) in users" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">#{{index+1 }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{
                        item.createdAt | moment("DD.MM.YYYY hh:mm")
                    }}</span>
                </td>
                <td>
                    <b-badge pill :variant="item.isActive ? 'light-success' : 'light-danger'">
                        {{ item.isActive ? "AKTİF" : "PASİF" }}
                    </b-badge>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{item.code }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{item.name }}</span>
                </td>
                <td>
                    <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                        <b-dropdown-item variant="flat-primary" :to="{ name: 'teacher-selection-page', params: { videoGroupId: item.id } }">
                            <feather-icon icon="ArchiveIcon" class="mr-50" />
                            <span class="align-middle">Detaylar</span>
                        </b-dropdown-item>
                        <b-dropdown-item variant="flat-primary" @click="editVD(item)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span class="align-middle">Düzenle</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteVD(item.id)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span class="align-middle">Sil</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </td>
            </tr>
        </draggable>

    </table>

    <!--Open Add Topic-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addVDPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Video Grup Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Konu Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addVDRequest.code" />
                </b-form-group>
                <b-form-group label="Konu Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addVDRequest.name" />
                </b-form-group>
                <b-form-group label="Konu Başarı Yüzdesi" label-for="name">
                    <b-form-input name="firstName" id="name" type="number" v-model="addVDRequest.percent" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addVD">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
    <!--Open Edit Topic-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editVDPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Video Grup Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Konu Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editVDRequest.code" />
                </b-form-group>
                <b-form-group label="Konu Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editVDRequest.name" />
                </b-form-group>
                <b-form-group label="Konu Başarı Yüzdesi" label-for="name">
                    <b-form-input name="firstName" id="name" type="number" v-model="editVDRequest.percent" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editVDRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateVD">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Video Grup Kodu' },
                { key: 'name', label: 'Video Grup Adı' },
                { key: 'percent', label: 'Video Grup Başarı Yüzdesi' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            addVDPanel: false,
            editVDPanel: false,
            addVDRequest: {
                topicId: 0,
                name: "",
                code: "",
                percent: 0
            },
            editVDRequest: {
                videoGroupId: 0,
                name: "",
                code: "",
                percent: 0,
                isActive: true
            },
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addVDPanel = true;
        });
        this.$root.$on('PreviewSaveClick', async () => {
            var index = 0;
            var request = [];
            this.users.forEach(element => {
                element.viewOrder = index;
                request.push({
                    refId: element.id,
                    viewOrder: element.viewOrder
                });
                index++;
            });
            await this.$http.put("Teacher/VideoGroupPreview", request);
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/VideoGroup/" + this.$route.params.topicId);
            this.users = users.data.data;
        },
        addVD() {
            this.addVDRequest.topicId = this.$route.params.topicId;
            this.addVDRequest.percent = parseInt(this.addVDRequest.percent);
            var request = this.addVDRequest;
            this.$http.post("Teacher/AddVideoGroup", request).then((data) => {
                    this.getData();
                    this.addVDPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Konu Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        editVD(data) {
            //console.log(data);
            this.editVDRequest = {
                videoGroupId: data.id,
                name: data.name,
                code: data.code,
                percent: data.percent,
                isActive: data.isActive
            }
            this.editVDPanel = true;
        },
        updateVD() {
            this.editVDRequest.percent = parseInt(this.editVDRequest.percent);
            var request = this.editVDRequest;
            this.$http.put("Teacher/UpdateVideoGroup", request).then((data) => {
                    this.getData();
                    this.editVDPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Video Grup Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteVD(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteVideoGroup/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Video Grup Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
